import CodeForm from "./CodeForm";
import "./Content.css";
import ImageLists from "./ImageLists";
import Tiles from "./Tiles";

const Content = () => {
	return (
		<div className="content">
			<div className="content__heading">
				<h1 style={{ fontFamily: "Recursive" }}>Image Resizer</h1>
				<hr className="new1" />
				<p>
					Resize images to the exact dimensions you want without losing quality. You can resize JPG, JPEG, PNG, or GIF files for free in seconds! 
				</p>
			</div>

			<a className="content__link" href="#imageForm">
				<h2>Upload and resize</h2>
			</a>
			<Tiles />
			<CodeForm />
			<ImageLists />

			<div className="content__segment">
				<h2>What is an image resizer?</h2>
				<p>
					An image resizer is a tool used to resize digital images. It is used to change the dimensions of an image. This can be done to make an image fit a certain size or shape.<br />
					Our online image resizer can be used to change the aspect ratio of an image for a variety of purposes, such as web design, graphic design, and photo editing. It can also be used to create thumbnails for websites or resize images for social media posts.
				</p>
			</div>

			<div className="content__segment">
				<h2>How to resize an image?</h2>
				<p>
					1. Upload your image, drag and drop, or paste an image link. Click the "Next" button.<br />
					2. Enter a new size for your image in the "Width" and "Height" fields. If you want to maintain the original image's ratio, keep the "Lock Aspect Ratio" option locked. Click the "Next" button.<br />
					3. Download your resized image.<br />
					4. Also, you can download our predefined resized images for social networks. Select the image sizes you want and click the "Selected" download button.
				</p>
				
			</div>

			<div className="content__segment">
				<h2>Resize images for social media</h2>
				<p>
					Our image resizer tool can automatically resize your image to the correct dimensions for things like a profile image for Instagram, a banner for Twitter, Google Ads, TikTok, and more.
				</p>
			</div>
		</div>
	);
};
export default Content;
export const facebookSizes = [
  {
    label: "Facebook Profile Picture",
    width: 248,
    height: 248,
    actualWidth: 180,
    actualHeight: 180,
    desc: "",
  },
  {
    label: "Facebook Link Image",
    width: 248,
    height: 130,
    actualWidth: 1200,
    actualHeight: 630,
    desc: "",
  },
  {
    label: "Facebook Image Post",
    width: 248,
    height: 130,
    actualWidth: 1200,
    actualHeight: 630,
    desc: "",
  },
  {
    label: "Facebook Cover Photo",
    width: 248,
    height: 94,
    actualWidth: 820,
    actualHeight: 312,
    desc: "",
  },
  {
    label: "Facebook Event Image",
    width: 248,
    height: 130,
    actualWidth: 1920,
    actualHeight: 1005,
    desc: "",
  },
  {
    label: "Facebook Group Cover",
    width: 248,
    height: 130,
    actualWidth: 1640,
    actualHeight: 856,
    desc: "",
  },
  {
    label: "Facebook Marketplace",
    width: 248,
    height: 248,
    actualWidth: 1200,
    actualHeight: 1200,
    desc: "",
  },
  {
    label: "Facebook Instant Articles",
    width: 248,
    height: 248,
    actualWidth: 1200,
    actualHeight: 1200,
    desc: "",
  },
  {
    label: "Facebook Highlighted Image",
    width: 248,
    height: 148,
    actualWidth: 1200,
    actualHeight: 717,
    desc: "",
  },
  {
    label: "Facebook Stories",
    width: 248,
    height: 441,
    actualWidth: 1080,
    actualHeight: 1920,
    desc: "",
  },
  {
    label: "Facebook Right Column",
    width: 248,
    height: 248,
    actualWidth: 1200,
    actualHeight: 1200,
    desc: "",
  },
  {
    label: "Facebook Feed",
    width: 248,
    height: 310,
    actualWidth: 1080,
    actualHeight: 1350,
    desc: "",
  },
];

export const instagramSizes = [
  {
    label: "Instagram Profile Pic Size",
    width: 248,
    height: 248,
    actualWidth: 320,
    actualHeight: 320,
    desc: "",
  },
  {
    label: "Instagram Square Size",
    width: 248,
    height: 248,
    actualWidth: 1080,
    actualHeight: 1080,
    desc: "",
  },
  {
    label: "Instagram Landscape Post",
    width: 248,
    height: 130,
    actualWidth: 1080,
    actualHeight: 566,
    desc: "",
  },
  {
    label: "Instagram Ad Square",
    width: 248,
    height: 248,
    actualWidth: 1080,
    actualHeight: 1080,
    desc: "",
  },
  {
    label: "Instagram Portrait Post",
    width: 248,
    height: 310,
    actualWidth: 1080,
    actualHeight: 1350,
    desc: "",
  },
  {
    label: "Instagram IGTV Cover",
    width: 248,
    height: 386,
    actualWidth: 420,
    actualHeight: 654,
    desc: "",
  },
  {
    label: "Instagram Stories",
    width: 248,
    height: 441,
    actualWidth: 1080,
    actualHeight: 1920,
    desc: "",
  },
  {
    label: "Instagram Reels",
    width: 248,
    height: 441,
    actualWidth: 1080,
    actualHeight: 1920,
    desc: "",
  },
  {
    label: "Instagram Ad Landscape",
    width: 248,
    height: 130,
    actualWidth: 1080,
    actualHeight: 566,
    desc: "",
  },
  {
    label: "Instagram Ad Portrait",
    width: 248,
    height: 310,
    actualWidth: 1080,
    actualHeight: 1350,
    desc: "",
  },
  {
    label: "Instagram Post Size",
    width: 248,
    height: 310,
    actualWidth: 1080,
    actualHeight: 1350,
    desc: "",
  },
  {
    label: "Instagram Thumbnail",
    width: 248,
    height: 248,
    actualWidth: 161,
    actualHeight: 161,
    desc: "",
  },
];

export const linkedinSizes = [
  {
    label: "LinkedIn Logo Size",
    width: 248,
    height: 248,
    actualWidth: 300,
    actualHeight: 300,
    desc: "",
  },
  {
    label: "LinkedIn Profile Picture",
    width: 248,
    height: 248,
    actualWidth: 400,
    actualHeight: 400,
    desc: "",
  },
  {
    label: "LinkedIn Dynamic Ad",
    width: 248,
    height: 248,
    actualWidth: 100,
    actualHeight: 100,
    desc: "",
  },
  {
    label: "LinkedIn Square Post",
    width: 248,
    height: 248,
    actualWidth: 1200,
    actualHeight: 1200,
    desc: "",
  },
  {
    label: "LinkedIn Stories",
    width: 248,
    height: 441,
    actualWidth: 1080,
    actualHeight: 1920,
    desc: "",
  },
  {
    label: "LinkedIn Portrait Post",
    width: 248,
    height: 310,
    actualWidth: 1080,
    actualHeight: 1350,
    desc: "",
  },
  {
    label: "LinkedIn Sponsored Content",
    width: 248,
    height: 129,
    actualWidth: 1200,
    actualHeight: 627,
    desc: "",
  },
  {
    label: "LinkedIn Blog Post Link",
    width: 248,
    height: 129,
    actualWidth: 1200,
    actualHeight: 627,
    desc: "",
  },
  {
    label: "LinkedIn Link Post",
    width: 248,
    height: 129,
    actualWidth: 1200,
    actualHeight: 627,
    desc: "",
  },
  {
    label: "LinkedIn Life Tab Image",
    width: 248,
    height: 83,
    actualWidth: 1128,
    actualHeight: 376,
    desc: "",
  },
  {
    label: "LinkedIn Background Photo",
    width: 248,
    height: 62,
    actualWidth: 1584,
    actualHeight: 396,
    desc: "",
  },
  {
    label: "LinkedIn Cover Photo",
    width: 248,
    height: 64,
    actualWidth: 1128,
    actualHeight: 191,
    desc: "",
  },
  {
    label: "LinkedIn Business Banner",
    width: 248,
    height: 84,
    actualWidth: 646,
    actualHeight: 220,
    desc: "",
  },
];

export const twitterSizes = [
  {
    label: "Twitter Card Image",
    width: 248,
    height: 248,
    actualWidth: 120,
    actualHeight: 120,
    desc: "",
  },
  {
    label: "Twitter Profile Picture Size",
    width: 248,
    height: 248,
    actualWidth: 400,
    actualHeight: 400,
    desc: "",
  },
  {
    label: "Twitter Website Card Ad",
    width: 248,
    height: 248,
    actualWidth: 800,
    actualHeight: 800,
    desc: "",
  },
  {
    label: "Twitter App Card Ad",
    width: 248,
    height: 248,
    actualWidth: 800,
    actualHeight: 800,
    desc: "",
  },
  {
    label: "Twitter Carousels",
    width: 248,
    height: 248,
    actualWidth: 800,
    actualHeight: 800,
    desc: "",
  },
  {
    label: "Twitter Fleets Images",
    width: 248,
    height: 441,
    actualWidth: 1080,
    actualHeight: 1920,
    desc: "",
  },
  {
    label: "Twitter Direct Message",
    width: 248,
    height: 130,
    actualWidth: 800,
    actualHeight: 418,
    desc: "",
  },
  {
    label: "Twitter Conversation Card",
    width: 248,
    height: 130,
    actualWidth: 800,
    actualHeight: 418,
    desc: "",
  },
  {
    label: "Twitter Video Thumbnail",
    width: 248,
    height: 140,
    actualWidth: 640,
    actualHeight: 360,
    desc: "",
  },
  {
    label: "Twitter Banner Size",
    width: 248,
    height: 83,
    actualWidth: 1500,
    actualHeight: 500,
    desc: "",
  },
  {
    label: "Twitter Post Image",
    width: 248,
    height: 140,
    actualWidth: 1200,
    actualHeight: 675,
    desc: "",
  },
];

export const googleAdsSizes = [
  {
    label: "Google Display Square",
    width: 248,
    height: 248,
    actualWidth: 250,
    actualHeight: 250,
    desc: "",
  },
  {
    label: "Google Display Small Square",
    width: 248,
    height: 248,
    actualWidth: 200,
    actualHeight: 200,
    desc: "",
  },
  {
    label: "Google Display Skyscraper",
    width: 248,
    height: 1240,
    actualWidth: 120,
    actualHeight: 600,
    desc: "",
  },
  {
    label: "Google Display Vertical Rectangle",
    width: 248,
    height: 413,
    actualWidth: 240,
    actualHeight: 400,
    desc: "",
  },
  {
    label: "Google Display Medium Rectangle",
    width: 248,
    height: 207,
    actualWidth: 300,
    actualHeight: 250,
    desc: "",
  },
  {
    label: "Google Display Inline Rectangle",
    width: 248,
    height: 207,
    actualWidth: 300,
    actualHeight: 250,
    desc: "",
  },
  {
    label: "Google Display Half Banner",
    width: 248,
    height: 64,
    actualWidth: 234,
    actualHeight: 60,
    desc: "",
  },
  {
    label: "Google Display Netboard",
    width: 248,
    height: 171,
    actualWidth: 580,
    actualHeight: 400,
    desc: "",
  },
  {
    label: "Google Display Half-Page Ad",
    width: 248,
    height: 496,
    actualWidth: 300,
    actualHeight: 600,
    desc: "",
  },
  {
    label: "Google Display Wide Skyscraper",
    width: 248,
    height: 930,
    actualWidth: 160,
    actualHeight: 600,
    desc: "",
  },
  {
    label: "Google Display Leaderboard",
    width: 248,
    height: 64,
    actualWidth: 728,
    actualHeight: 90,
    desc: "",
  },
  {
    label: "Google Display Mobile Banner",
    width: 248,
    height: 64,
    actualWidth: 300,
    actualHeight: 50,
    desc: "",
  },
  {
    label: "Google Display Banner",
    width: 248,
    height: 64,
    actualWidth: 468,
    actualHeight: 68,
    desc: "",
  },
  {
    label: "Google Display Triple Widescreen",
    width: 248,
    height: 180,
    actualWidth: 250,
    actualHeight: 360,
    desc: "",
  },
  {
    label: "Google Display Portrait",
    width: 248,
    height: 868,
    actualWidth: 300,
    actualHeight: 1050,
    desc: "",
  },
  {
    label: "Google Display Large Leaderboard",
    width: 248,
    height: 64,
    actualWidth: 970,
    actualHeight: 90,
    desc: "",
  },
  {
    label: "Google Display Large Rectangle",
    width: 248,
    height: 207,
    actualWidth: 336,
    actualHeight: 280,
    desc: "",
  },
];

export const snapchatSizes = [
  {
    label: "Snapchat Image Share",
    width: 248,
    height: 441,
    actualWidth: 1080,
    actualHeight: 1920,
    desc: "Adhere to 1080 x 1920 pixels. Give your younger target audience greater incentives to come to your brand with Snapchat image sharing for coupons and offers. Include emojis or fun filters and add short text since you can’t use captions.",
  },
];

export const youtubeSizes = [
  {
    label: "YouTube Profile Picture",
    width: 248,
    height: 248,
    actualWidth: 250,
    actualHeight: 250,
    desc: "",
  },
  {
    label: "YouTube Banner",
    width: 248,
    height: 140,
    actualWidth: 2560,
    actualHeight: 1440,
    desc: "",
  },
  {
    label: "YouTube Thumbnail Size",
    width: 248,
    height: 140,
    actualWidth: 1280,
    actualHeight: 720,
    desc: "",
  },
  {
    label: "YouTube Companion Banner",
    width: 248,
    height: 64,
    actualWidth: 300,
    actualHeight: 60,
    desc: "",
  },
  {
    label: "YouTube Overlay Ads",
    width: 248,
    height: 64,
    actualWidth: 480,
    actualHeight: 60,
    desc: "",
  },
  {
    label: "YouTube Display Ads",
    width: 248,
    height: 207,
    actualWidth: 300,
    actualHeight: 250,
    desc: "",
  },
];

export const youtubeChannelArtSizes = [
  {
    label: "YouTube Channel Art Tablet Banner",
    width: 248,
    height: 57,
    actualWidth: 1855,
    actualHeight: 423,
    desc: "",
  },
  {
    label: "YouTube Channel Art TV",
    width: 248,
    height: 140,
    actualWidth: 2560,
    actualHeight: 1440,
    desc: "",
  },
  {
    label: "YouTube Channel Art Mobile Banner",
    width: 248,
    height: 69,
    actualWidth: 1546,
    actualHeight: 423,
    desc: "",
  },
  {
    label: "YouTube Channel Art Desktop Banner",
    width: 248,
    height: 64,
    actualWidth: 2560,
    actualHeight: 423,
    desc: "",
  },
];

export const websiteSizes = [
  {
    label: "Website Portrait Content",
    width: 248,
    height: 372,
    actualWidth: 1000,
    actualHeight: 1500,
    desc: "",
  },
  {
    label: "Website Full Width",
    width: 248,
    height: 165,
    actualWidth: 2400,
    actualHeight: 1600,
    desc: "",
  },
  {
    label: "Website Landscape Content",
    width: 248,
    height: 165,
    actualWidth: 1500,
    actualHeight: 1000,
    desc: "",
  },
];

export const emailSizes = [
  {
    label: "Email Blog Image",
    width: 248,
    height: 248,
    actualWidth: 750,
    actualHeight: 750,
    desc: "",
  },
  {
    label: "Email Blog Featured",
    width: 248,
    height: 124,
    actualWidth: 1200,
    actualHeight: 600,
    desc: "",
  },
  {
    label: "Email Header Image",
    width: 248,
    height: 83,
    actualWidth: 600,
    actualHeight: 200,
    desc: "",
  },
];

export const tiktokSizes = [
  {
    label: "TikTok In-Feed Ad Image",
    width: 248,
    height: 248,
    actualWidth: 1080,
    actualHeight: 1080,
    desc: "",
  },
  {
    label: "TikTok PFP",
    width: 248,
    height: 248,
    actualWidth: 200,
    actualHeight: 200,
    desc: "",
  },
  {
    label: "TikTok Square Video Ad",
    width: 248,
    height: 248,
    actualWidth: 1080,
    actualHeight: 1080,
    desc: "",
  },
  {
    label: "TikTok Vertical Video Ad",
    width: 248,
    height: 441,
    actualWidth: 1080,
    actualHeight: 1920,
    desc: "",
  },
  {
    label: "TikTok Horizontal Video Ad",
    width: 248,
    height: 140,
    actualWidth: 1920,
    actualHeight: 1080,
    desc: "",
  },
];

export const pinterestSizes = [
  {
    label: "Pinterest Portrait Carousel",
    width: 248,
    height: 372,
    actualWidth: 1000,
    actualHeight: 1500,
    desc: "",
  },
  {
    label: "Pinterest Vertical Pin",
    width: 248,
    height: 372,
    actualWidth: 1000,
    actualHeight: 1500,
    desc: "",
  },
  {
    label: "Pinterest PFP",
    width: 248,
    height: 248,
    actualWidth: 165,
    actualHeight: 165,
    desc: "",
  },
  {
    label: "Pinterest Square Carousel",
    width: 248,
    height: 248,
    actualWidth: 1000,
    actualHeight: 1000,
    desc: "",
  },
  {
    label: "Pinterest Story Pins",
    width: 248,
    height: 441,
    actualWidth: 1080,
    actualHeight: 1920,
    desc: "",
  },
  {
    label: "Pinterest Board Display",
    width: 248,
    height: 140,
    actualWidth: 222,
    actualHeight: 150,
    desc: "",
  },
];

export const facebookAdsSizes = [
  {
    label: "Facebook Ads Carousel Image",
    width: 248,
    height: 248,
    actualWidth: 1200,
    actualHeight: 1200,
    desc: "",
  },
  {
    label: "Facebook Ads Image Ad",
    width: 248,
    height: 130,
    actualWidth: 1200,
    actualHeight: 628,
    desc: "",
  },
  {
    label: "Facebook Ads Audience Network",
    width: 248,
    height: 130,
    actualWidth: 1200,
    actualHeight: 628,
    desc: "",
  },
  {
    label: "Facebook Ads Messenger Image Ad",
    width: 248,
    height: 130,
    actualWidth: 1200,
    actualHeight: 628,
    desc: "",
  },
  {
    label: "Facebook Ads Audience Network Link",
    width: 248,
    height: 130,
    actualWidth: 398,
    actualHeight: 208,
    desc: "",
  },
  {
    label: "Facebook Ads Story Ad",
    width: 248,
    height: 441,
    actualWidth: 1080,
    actualHeight: 1920,
    desc: "",
  },
];

export const sizesArray = [
  facebookSizes,
  instagramSizes,
  facebookAdsSizes,
  googleAdsSizes,
  pinterestSizes,
  tiktokSizes,
  snapchatSizes,
  websiteSizes,
  emailSizes,
  twitterSizes,
  linkedinSizes,
  youtubeChannelArtSizes,
  youtubeSizes,
];